import React, { useState, useEffect } from "react"

const MlTechStack = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  const handleResize = () => {
    setIsDesktop(window.innerWidth < 650)
  }

  return (
    <>
      <div className="relative bg-white">
        <div
          className=" inset-0 bg-cover w-full"
          // style={{
          //   backgroundImage:
          //     'url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/12/Tech-stack-1.webp")',
          // }}
        >
          <div className="relative h-full max-w-screen-2xl mx-auto px-4 py-16">
            <div className="text-center mb-5">
              <h1
                className="text-2xl md:text-5xl font-bold text-[rgba(36, 38, 43, 1)] px-6 md:px-10 py-1 md:py-2 inline-block"
                style={{
                  borderRadius: "40px",
                  border: "6px solid rgba(255, 249, 249, 0.08)",
                  background: "rgba(255, 255, 255, 0.16)",
                }}
              >
                Machine Learning Technologies and Tools We Use
              </h1>
            </div>
            <div className="text-center mb-9">
              <p className="text-lg text-[rgba(36, 38, 43, 1)] max-w-3xl mx-auto">
                To deliver top-notch Machine Learning Solutions, we use the
                latest technologies and tools in the industry
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={
                  isDesktop
                    ? "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/02/Machine-Learning-Technologies-and-Tools-We-Use-MobileView.webp"
                    : "https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/02/Machine-Learning-Technologies-and-Tools-We-Use-Webview.webp"
                }
                alt="Industries"
                className="max-w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MlTechStack
