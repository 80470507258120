import React, { useState } from "react"
import Slider from "react-slick"
import { MessageSquare, Video, SearchIcon } from "lucide-react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./AIAgentsSlider.css"
import SliderComponent from "../../SliderComponent"

export default function AiBusinessApproach() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const items = [
    {
      number: "01",
      title: "Enhance Operational Efficiency",
      icon: (
        <i
          className="icon-ai-chatbot-development-lead-geeneration-qualification"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),

      description:
        "AI agents handle time-consuming tasks like data entry, scheduling, and basic customer queries, allowing your team to focus on higher-value activities. This leads to greater productivity and cost savings.",
    },
    {
      number: "02",
      title: "Automate Complex Workflows",
      icon: (
        <i
          className="icon-ai-chatbot-development-personalized-user-experience"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "By incorporating machine learning and automation, AI agents can streamline multi-step processes, handling tasks that were once cumbersome and manual. This leads to smoother workflows and faster turnaround times.",
    },
    {
      number: "03",
      title: "Offer Scalable Solutions",
      icon: (
        <i
          className="icon-ai-chatbot-development-ai-enhanced-analytics"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "As your business grows, AI agents can easily scale with you. They can manage increasing workloads without compromising performance, ensuring that you’re always ready to meet new challenges.",
    },
    {
      number: "04",
      title: "Provide 24/7 Customer Engagement",
      icon: (
        <i
          className="icon-ai-chatbot-development-24-7-customer-support"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents can engage with customers round the clock, offering support, answering questions, and providing recommendations without the need for human intervention. This continuous availability improves customer satisfaction and retention.",
    },
    ,
    {
      number: "05",
      title: "Personalize User Experiences",
      icon: (
        <i
          className="icon-ai-chatbot-development-24-7-customer-support"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "AI agents can engage with customers round the clock, offering support, answering questions, and providing recommendations without the need for human intervention. This continuous availability improves customer satisfaction and retention.",
    }
    
  ]

  const sliderRef = React.useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative w-full bg-blue-500 overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        // style={{
        //   backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Our-AI-Approach.webp")`,
        // }}
      />
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1
            className="text-2xl md:text-5xl font-bold text-white px-6 md:px-10 py-1 md:py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            AI Chatbot Development Solutions for Businesses
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-white">
          With our AI Chatbot Development solutions, we offer businesses a wide
          range of capabilities to optimize customer interactions, improve
          engagement, and boost operational efficiency. Our AI-powered chatbots
          are designed to provide real-time, relevant, and personalized
          assistance.
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <SliderComponent
                key={index}
                number={item.number}
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-left-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>

            <span className="text-white text-lg font-bold">
              {currentSlide + 1} /{" "}
              <span className="text-white text-lg font-bold opacity-50 ">
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-white/30 bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
            >
              <i
                className={`icon-slider-right-arrow text-white flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
