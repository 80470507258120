import React from "react"
import "./AiEngineers.css"
const AiEngineers = () => {
  const industries = [
    {
      title: "E-commerce AI Solutions",
      description:
        "Transform your e-commerce business with AI-driven personalization, recommendation engines, and automated customer service. Our AI engineers help you create tailored shopping experiences that increase customer satisfaction and drive sales.",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-e-commerce-ai-solutions"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Healthcare AI Solutions",
      description:
        "In healthcare, AI is revolutionizing diagnostics, patient care, and operational efficiency. Our developers create AI solutions that improve patient outcomes, automate administrative tasks, and provide intelligent insights for decision-making..",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-healthcare-ai-solutions
"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Finance and Banking AI Solutions",
      description:
        "From fraud detection to algorithmic trading, AI plays a crucial role in the finance industry. We build AI systems that help financial institutions automate processes, enhance security, and make data-driven decisions.",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-finance-and-banking-ai-solutions
"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Manufacturing AI Solutions",
      description:
        "AI can optimize manufacturing processes, improve quality control, and predict maintenance needs. Our AI engineers help you automate production lines, monitor equipment performance, and reduce operational costs.",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-manufacturing-ai-solutions
"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Retail AI Solutions",
      description:
        "In the retail industry, AI enables personalized shopping experiences, demand forecasting, and inventory management. Our developers craft AI solutions that empower your retail business to meet customer expectations while improving operational efficiency.",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-retail-ai-solutions
"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
    {
      title: "Logistics and Supply Chain AI Solutions",
      description:
        "AI-driven predictive analytics and route optimization are transforming supply chain management. Our AI engineers create solutions that streamline logistics, enhance real-time tracking, and optimize delivery routes.",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-logistics-and-supply-chain-ai-solutions
"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/Build-Custom-Use-Cases-of-Your-Industry-with-Our-AI-Engineers.webp")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full max-w-7xl mx-auto">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          Build Custom Use Cases of Your Industry with Our AI Engineers
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center mx-auto mb-8 max-w-4xl">
          AI is not a one-size-fits-all solution. It's about customizing the
          technology to meet the specific demands of your industry. Our AI
          engineers understand the nuances of various industries and are experts
          in developing tailored solutions that drive tangible results.
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {industries.map((industry, index) => (
            <div key={index} className="feature-tagAI flex flex-col">
              <div className="flex flex-col h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <p className="text-base lg:text-lg text-white">
                  {industry.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AiEngineers
